// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

import "./src/css/bootstrap.min.css"
import "./src/css/owl.carousel.min.css"
import "./src/css/animate.min.css"
import "./src/css/magnific-popup.css"
import "./src/css/fontawesome-all.min.css"
import "./src/css/themify-icons.css"
import "./src/css/slick.css"
import "./src/css/default.css"
import "./src/css/style.css"
import "./src/css/responsive.css"
import "./src/css/custom.css"
